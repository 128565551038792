import React, {useEffect, useState} from 'react';
import './App.css';

const IOsInstallLink = ({isLogin, hasPermission}: Props) => {
    if (isLogin && hasPermission) {
        return (
            <div>
                <h2>iOS</h2>
                <h5>It may take about 30 seconds for the dialog to appear after tapping the link.</h5>
                <a href={`itms-services://?action=download-manifest&url=https://${window.location.hostname}/api/download/manifest.plist`}>Install</a>
            </div>
        )
    }
    return (
        <div/>
    )
}

const HelloMessage = ({isLogin, hasPermission, userId}: Props) => {
    if (isLogin) {
        if (hasPermission) {
            return (
                <div>
                    <div>
                        Hello, {userId}!!
                    </div>
                    <div>
                        Now, you can download and install our apps!
                    </div>
                </div>

            )
        } else {
            return (
                <div>
                    <div>
                        Hello, {userId}!!
                    </div>
                    <div>
                        Oops!, you do NOT have a permission for downloading and installing our apps.
                        please reach out to your administrator.
                    </div>
                </div>
            )
        }
    }
    return (<div/>)
}

type Props = {
    isLogin: boolean,
    hasPermission: boolean,
    userId?: string,
}

const App = () => {
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [user, setUser] = useState<ClientPrincipal>({
        identityProvider: 'dummy',
        userId: 'dummy',
        userRoles: ['dummy'],
        userDetails: 'dummy'
    })
    useEffect(() => {
        const validateUser = async () => {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const {clientPrincipal} = payload;

            if (clientPrincipal) {
                setIsLogin(true)
                setUser(clientPrincipal)
            } else {
                setIsLogin(false)
            }
        }
        validateUser()
    }, [isLogin])

    return (
        <div className="App">
            <header>
                <h1>
                    Application Distributor
                </h1>
                <h2>
                    <HelloMessage isLogin={isLogin} hasPermission={user.userRoles.includes('downloader')} userId={user.userDetails}/>
                </h2>
            </header>
            <body>
                <h2>
                    {
                        isLogin ?
                            <a href={`logout`}>logout</a>
                            :
                            <a href={`login`}>login</a>
                    }
                </h2>
                <h3>
                    Sirius-Proto Mobile App
                </h3>
                <div>
                    <IOsInstallLink isLogin={isLogin} hasPermission={(user.userRoles.includes('downloader'))}/>
                </div>
            </body>
        </div>
    );
}

type ClientPrincipal = {
    identityProvider: string,
    userId: string,
    userDetails: string,
    userRoles: string[],
}

export default App
